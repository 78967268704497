<!--
	Elements inside AppTabs.
	Each should have a name which goes in the tab at the top
	as well as a slot for content to display when that tab is selected.
-->
<script>
  import { fade } from 'svelte/transition'
  import { getContext, onMount } from 'svelte'

  export let name

  const { registerTab, selectedTab } = getContext('app-tabs')

  onMount(() => {
    registerTab(name)
  })
</script>

{#if $selectedTab === name}
  <div in:fade={{ duration: 200 }}>
    <slot />
  </div>
{/if}
