<script>
  import Section from './widgets/Section.svelte'
  import AppTabs from '../AppTabs.svelte'
  import AppTab from '../AppTab.svelte'

  import scanUrl from '../../images/scan.png'
  import monitorUrl from '../../images/monitor.png'
  import maintenanceUrl from '../../images/maintenance.png'
  import toolboxUrl from '../../images/toolbox.png'
  import premiumUrl from '../../images/premium.png'

  export let premiumPricePerMonth
</script>

<svelte:head>
  <!-- preload all the image assets to avoid animation flicker -->
  <link rel="preload" href={scanUrl} as="image" />
  <link rel="preload" href={monitorUrl} as="image" />
  <link rel="preload" href={maintenanceUrl} as="image" />
  <link rel="preload" href={toolboxUrl} as="image" />
  <link rel="preload" href={premiumUrl} as="image" />
</svelte:head>

<Section center="true" background="light-grey">
  <slot name="header">
    <h1>Inside the FIXD App</h1>
  </slot>
  <div class="w-full lg:w-[60%] mx-auto text-center">
    <AppTabs>
      <AppTab name="Scan">
        <div class="block lg:flex items-center">
          <div class="basis-1/2">
            <img src={scanUrl} class="w-1/2" alt="Scan" />
          </div>
          <div class="text-left basis-1/2">
            <div class="mb-4 lg:text-center">
              <h2>Tap to scan</h2>
            </div>
            <p>
              Why pay a $90+ diagnostic fee at a repair shop when FIXD can help
              diagnose problems in seconds?
            </p>
            <p class="my-4">
              One tap, and FIXD will report diagnostic info in terms you don't
              have to be a mechanic to understand.
            </p>
            <p>
              FIXD even tells you the severity of each issue, so you know what
              can wait and what can't.
            </p>
          </div>
        </div>
      </AppTab>
      <AppTab name="Monitor">
        <div class="block lg:flex items-center">
          <div class="basis-1/2">
            <img src={monitorUrl} class="w-1/2" alt="Monitor" />
          </div>
          <div class="text-left basis-1/2">
            <div class="mb-4 lg:text-center">
              <h2>Multi-vehicle monitoring</h2>
            </div>
            <p>
              You and your family depend on your cars. FIXD helps you keep your
              vehicles running in top condition, thanks to the simple app
              dashboard
            </p>
            <p class="my-4">
              Inside the dashboard, you can monitor the health of all of your
              cars, right in one place.
            </p>
            <p>
              When a loved one is driving across town or across the country,
              that means <i>peace of mind</i>.
            </p>
          </div>
        </div>
      </AppTab>
      <AppTab name="Maintenance">
        <div class="block lg:flex items-center">
          <div class="basis-1/2">
            <img src={maintenanceUrl} class="w-1/2" alt="Maintenance" />
          </div>
          <div class="text-left basis-1/2">
            <div class="mb-4 lg:text-center">
              <h2>Maintenance timeline & reminders</h2>
            </div>
            <p class="mb-4">
              Terrible at keeping car maintenance records? FIXD has you covered.
              You can access and update your car's maintenance history with a
              single tap.
            </p>
            <p>
              You can also set reminders for oil changes, tire rotations, and
              other routine maintenance to keep your car performing better - and
              longer.
            </p>
          </div>
        </div>
      </AppTab>
      <AppTab name="Toolbox">
        <div class="block lg:flex items-center">
          <div class="basis-1/2">
            <img src={toolboxUrl} class="w-1/2" alt="Toolbox" />
          </div>
          <div class="text-left basis-1/2">
            <div class="mb-4 lg:text-center">
              <h2>All of your car care tools</h2>
            </div>
            <div class="mb-4">
              <p>
                We've made it easy to find the tools you need inside the FIXD
                app. In the Toolbox tab, you'll have access to resources that
                will help save you time, money, and stress over the life of your
                car
              </p>
            </div>
            <p>
              Plus, we've included a few extras to make driving just a bit more
              fun!
            </p>
          </div>
        </div>
      </AppTab>
      <AppTab name="Premium*">
        <div class="block lg:flex items-center">
          <div class="basis-1/2">
            <img src={premiumUrl} class="w-1/2" alt="Premium" />
          </div>
          <div class="text-left basis-1/2">
            <div class="mb-4 lg:text-center">
              <h2>Step up to FIXD Premium</h2>
            </div>

            <p>With the optional Premium subscription, you get access to:</p>
            <ul class="pl-5 list-disc my-4">
              <li>Confirmed estimates at RepairPal</li>
              <li>Certified® shops - what you see is what you'll pay.</li>
              <li>
                Members-only Mechanic Hotline - get one-on-one advice and
                do-it-yourself repair guidance.
              </li>
              <li>Issue forecasts to help you predict future repairs</li>
              <li>And much more!</li>
            </ul>
            <p>
              All for just {premiumPricePerMonth.toString()} a month, billed annually
            </p>
          </div>
        </div>
      </AppTab>
    </AppTabs>
  </div>
</Section>
