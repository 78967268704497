<!-- 
	Container for a UI element with a set of tabs at the top and content below for each
	tab. Expects to be filled with AppTab components.
 -->
<script>
  import { setContext } from 'svelte'
  import { writable } from 'svelte/store'

  import { clickOrEnterHandler } from '@/util/svelte.js'

  const tabNames = writable(new Set())
  const selectedTab = writable(null)

  // called by AppTab components when they are added to AppTabs
  function registerTab(name) {
    tabNames.update((set) => {
      if (set.has(name)) {
        throw new Error(
          `app-tab element ${name} was added multiple times. Elements must have unique names`,
        )
      }
      set.add(name)
      return set
    })
    // if none has been selected yet, select the first to be registered
    selectedTab.update((v) => v || name)
  }

  function selectTab(name) {
    selectedTab.set(name)
  }

  setContext('app-tabs', { selectedTab, registerTab })
</script>

<div class="app-tabs">
  <div
    class="flex justify-center gap-2.5 flex-row flex-wrap border-b border-grey"
  >
    {#each Array.from($tabNames) as tabName}
      <div
        class="p-4 cursor-pointer font-semibold hover:text-green"
        class:text-green={tabName === $selectedTab}
        use:clickOrEnterHandler={() => selectTab(tabName)}
      >
        {tabName}
      </div>
    {/each}
  </div>

  <div class="mx-auto pt-7">
    <slot />
  </div>
</div>
